import { isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  input,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appAutoClose]',
  standalone: true
})
export class AutoCloseDirective implements AfterViewInit {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);
  private animationDuration = 300;
  private disabled = false;

  appAutoClose = input<'always' | 'outside'>('always');

  ngAfterViewInit(): void {
    const ul = this.el.nativeElement.children[1];
    ul.classList.add(`duration-${this.animationDuration}`);
    ul.classList.add('hidden');
  }

  constructor() {
    if (isPlatformServer(inject(PLATFORM_ID))) {
      return;
    }
    this.renderer.listen('window', 'click', event => {
      if (this.disabled) {
        return;
      }
      this.disabled = true;
      setTimeout(() => this.disabled = false, this.animationDuration);
      const [ button, ul ] = this.el.nativeElement.children;
      if (button.contains(event.target) && ul.classList.contains('hidden')) {
        ul.classList.remove('hidden');
        setTimeout(() => {
          ul.classList.remove('opacity-0');
          ul.classList.add('opacity-100');
        });
        return;
      }
      if (this.appAutoClose() === 'outside'
        && this.el.nativeElement.contains(event.target)
        && !button.contains(event.target)
        && !(event.target instanceof HTMLButtonElement)
        && !(event.target instanceof HTMLAnchorElement)
      ) {
        return;
      }
      setTimeout(() => {
        ul.classList.add('hidden');
      }, this.animationDuration);
      ul.classList.add('opacity-0');
      ul.classList.remove('opacity-100');
    });
  }
}
